<template>
    <section>
        <div
            class="d-flex fs-13 align-items-center justify-content-center py-4"
        >
            <span class="py-2">
                © Copyright 2012 - 2021 | Avada Theme by
                <a class="text-glow" href="#">ThemeFusion</a> | All Rights
                Reserved | Powered by
                <a class="text-glow" href="#">WordPress</a>
            </span>
        </div>
    </section>
</template>

<script>
export default {
    name: "Copyright",
};
</script>

<style scoped lang="scss">
@import "../styles/globals.scss";

a {
    text-decoration: none;
    color: black;
}
</style>
